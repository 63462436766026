@media (max-width: 74em) {
  .about-box {
    max-width: 100%;
    right: 0;
  }
}

@media (max-width: 59em) {
  html {
    font-size: 50%;
  }

  .btn-mobile-nav {
    z-index: 9999;
    display: block;
  }

  .header {
    z-index: 2;
  }

  .main-nav {
    width: 100%;
    height: 100vh;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    background-color: #fffc;
    justify-content: center;
    align-items: center;
    transition: all .5s ease-out;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(100%);
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    position: fixed;
    transform: translateX(0);
  }

  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
    position: fixed;
    top: 4%;
    right: 10%;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 4.8rem;
  }

  .main-nav-link:link, .main-nav-link:visited, .dropdown .dropbtn, .dropdown-content a {
    font-size: 3rem;
  }
}

@media (max-width: 46em) {
  .cal-container {
    grid-template-columns: 1fr;
    padding: 0;
    display: grid;
  }

  .embed-cal {
    width: 90vw;
  }

  .meeting-minutes-container, .financial-reports-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 38em) {
  .club-shoot-info .section-header {
    text-align: center;
    padding-left: 0;
  }

  .club-shoot-container {
    text-align: center;
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .club-container {
    grid-template-columns: 1fr;
  }

  .club-shoot-info h3 {
    text-align: center;
  }

  .club-resource-container {
    flex-direction: column;
  }

  .club-shoot-info h3 {
    text-align: center;
  }

  .club-resource-container {
    flex-direction: column;
  }

  .hof-section h2 {
    text-align: center;
    padding-left: 0;
  }
}

@media (max-width: 27em) {
  .welcome, .welcome-cta {
    position: static;
  }

  .cta:link, .cta:visited {
    width: auto;
  }

  .about-box {
    width: auto;
    margin-top: 1rem;
    margin-bottom: 4.8rem;
    position: static;
  }

  .club-shoot-info .section-header {
    margin-bottom: 4rem;
  }

  .club-shoot-container {
    padding-bottom: 4rem;
  }

  .club-shoot-container .shoot-heading {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .governing-docs-iframe {
    height: 700px;
  }

  body {
    min-height: 100vh;
  }

  .mobile {
    text-align: center;
    margin-bottom: 4rem;
    padding-bottom: 0;
    display: block;
  }

  .meeting-header {
    text-align: center;
  }

  .meeting-minute-img {
    display: none;
  }

  .club-shoot-info .grid {
    gap: 0;
  }

  .hof-section .section-header {
    margin: 4rem;
  }
}

/*# sourceMappingURL=index.12e5da06.css.map */
