@media (max-width: 74em) {
   .about-box {
      max-width: 100%;
      right: 0;
   }
}

@media (max-width: 64em) {
}
@media (max-width: 59em) {
   html {
      font-size: 50%;
   }

   .btn-mobile-nav {
      display: block;
      z-index: 9999;
   }

   .header {
      z-index: 2;
   }

   .main-nav {
      background-color: rgba(255, 255, 255, 0.8);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      transform: translateX(100%);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);

      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease-out;

      /* Hiding navigation so it doesn't interfere */
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
   }

   .nav-open .main-nav {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
      transform: translateX(0);
      position: fixed;
   }

   .nav-open .icon-mobile-nav[name="close-outline"] {
      display: block;
      position: fixed;
      top: 4%;
      right: 10%;
   }

   .nav-open .icon-mobile-nav[name="menu-outline"] {
      display: none;
   }

   .main-nav-list {
      flex-direction: column;
      gap: 4.8rem;
   }

   .main-nav-link:link,
   .main-nav-link:visited {
      font-size: 3rem;
   }

   .dropdown .dropbtn {
      font-size: 3rem;
   }

   .dropdown-content a {
      font-size: 3rem;
   }
}

/* @media (max-width: 58em) and (max-height: 29em) {
  .about-box {
    top: 50%;
  }
  .welcome {
    height: 105vh;
  }
} */

@media (max-width: 46em) {
   .cal-container {
      display: grid;
      grid-template-columns: 1fr;
      padding: 0;
   }

   .embed-cal {
      width: 90vw;
   }

   .meeting-minutes-container {
      grid-template-columns: 1fr;
   }

   .financial-reports-container {
      grid-template-columns: 1fr;
   }
}

@media (max-width: 38em) {
   .club-shoot-info .section-header {
      text-align: center;
      padding-left: 0;
   }

   .club-shoot-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
   }

   .club-container {
      grid-template-columns: 1fr;
   }

   .club-shoot-info h3 {
      text-align: center;
   }

   .club-resource-container {
      flex-direction: column;
   }

   .club-shoot-info h3 {
      text-align: center;
   }

   .club-resource-container {
      flex-direction: column;
   }

   .hof-section h2 {
      text-align: center;
      padding-left: 0;
   }
}

@media (max-width: 27em) {
   .welcome {
      position: static;
   }

   .welcome-cta {
      position: static;
   }

   .cta:link,
   .cta:visited {
      width: auto;
   }

   .about-box {
      width: auto;
      position: static;
      margin-top: 1rem;
      margin-bottom: 4.8rem;
   }

   .club-shoot-info .section-header {
      margin-bottom: 4rem;
   }

   .club-shoot-container {
      padding-bottom: 4rem;
   }

   .club-shoot-container .shoot-heading {
      padding-bottom: 0;
      margin-bottom: 0;
   }

   .governing-docs-iframe {
      height: 700px;
   }

   body {
      min-height: 100vh;
   }

   .mobile {
      display: block;
      margin-bottom: 4rem;
      padding-bottom: 0;
      text-align: center;
   }

   .meeting-header {
      text-align: center;
   }

   .meeting-minute-img {
      display: none;
   }

   .club-shoot-info .grid {
      gap: 0;
   }

   .hof-section .section-header {
      margin: 4rem;
   }
}
